.LoadingBar {
  width: 100%;
  height: 0.5em;
  background-color: #99999999;
  margin: 0.5em 0;
  overflow: hidden;
}

.LoadingBar > div {
  background-color: green;
  animation: load 1s infinite ease-in-out;
  height: 0.5em;
}

.LoadingBar > div {
  background-color: green;
  animation: load 1s infinite ease-in-out;
  height: 0.5em;
}

@keyframes load {
  0% {
    width: 0%;
    margin-left: 0%;
  }

  50% {
    width: 100%;
    margin-left: 0%;
  }

  100% {
    width: 0%;
    margin-left: 100%;
  }
}