.App {
  background: #eee;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  padding: 1rem;
}

.App th {
  border: 1px solid #cccccccc;
}

.App .material-icons {
  width: 100%;
}

.App td,
.App th {
  padding: 4px 7px;
  text-align: center;
}

.App .red {
  background-color: orange;
}

.App .material-icons.option {
  user-select: none;
}

table {
  width: 100%;
  color: #222;
  border-collapse: collapse;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 1px solid #34485e;
}

table > tbody > tr:nth-of-type(odd) {
  background-color: #eee;
}

table > tbody > tr:nth-of-type(even) {
  background-color: #ccc;
}

table td, table th {
  padding: 0.4em;
}

table > thead {
  background-color: #34485e;
  color: white;
  text-align: left;
}

table td, table th, table tr, table > tbody {
  border: none;
}

button {
  border: 1px solid #34485e;
  border-radius: 3px;
  background: transparent;
  cursor: pointer;
}

button:hover {
  border: 1px solid #34485e;
  border-radius: 3px;
  background: #ffffff99;
  color: #34485e;
}

.toolbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.toolbar > button {
  padding: 5px;
  margin: 5px;
}
